import { useEffect, useRef, useState } from 'react';
import {
  Container as MapDiv,
  NaverMap,
  useNavermaps,
  Marker,
} from 'react-naver-maps';
import styled from 'styled-components';

function generateInfoWindowContentFromMarker(point, originalPoint) {
  if (originalPoint) {
    return `
    <div style="position: relative; padding: 15px; font-size: 14px; background-color: white; 
                    border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
          <button style="position: absolute; top: 5px; right: 5px; border: none; background: none; 
                         cursor: pointer; font-size: 16px;" 
                  onclick="closeInfoWindow()">X</button>
          <h3 style="margin: 0; font-size: 16px;">${
            point.id
          } &nbsp&nbsp&nbsp&nbsp ${
      point.direction === 0 ? `` : `<span>설정각: ${point.direction}</span>`
    }</h3>
          <p style="margin-top: 5px;">기존 위도: ${
            originalPoint.latitude
          }, 경도: ${originalPoint.longitude}</p>
          <p style="margin-top: 5px;">새로운 위도: ${point.latitude}, 경도: ${
      point.longitude
    }</p>
          <button style="margin-top: 10px;" onclick="handleResetPosition(${
            point.id
          })">리셋</button>
          <button style="margin-top: 5px;" onclick="updateMarkerPosition(${
            point.id
          }, ${point.latitude}, ${point.longitude})">변경</button>
          <button style="margin-top: 10px;" onclick="editStartingPoint('${
            point.id
          }')">
                    시작포인트 지정</button>
          <button style="margin-top: 10px;" onclick="showViewer('${
            point.id
          }')">공간뷰어</button>
        </div>
  `;
  } else {
    return `<div style="padding: 15px; font-size: 14px; background-color: white; 
                    border-radius: 0; border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
          <button style="position: absolute; top: 5px; right: 5px; border: none; background: none; 
                cursor: pointer; font-size: 16px;" 
                  onclick="closeInfoWindow()">X</button>
          <h3 style="margin: 0; font-size: 16px;">${
            point.id
          } &nbsp&nbsp&nbsp&nbsp ${
      point.direction === 0 ? `` : `<span>설정각: ${point.direction}</span>`
    }</h3>
          <p style="margin-top: 5px;">위도: ${point.latitude}, 경도: ${
      point.longitude
    }</p>
          <button style="margin-top: 10px;" onclick="editStartingPoint('${
            point.id
          }')">
                    시작포인트 지정</button>
          <button style="margin-top: 10px;" onclick="showViewer('${
            point.id
          }')">공간뷰어</button>
        </div>`;
  }
}

function generateInfoWindowContentFromMap(lat, lng, itemsWithoutLatLong) {
  // 스크롤 리스트 형태로 아이템을 표시
  let itemListContent = `
      <div style="margin-top: 15px; max-height: 200px; overflow-y: scroll;">
        <ul style="list-style-type: none; padding: 0;">
          ${itemsWithoutLatLong
            .map((item) => {
              const fileName = item.img_thumbnail.split('/').pop();
              return `
                <li style="margin: 5px 0;">
                  <button 
                    style="
                      width: 100%; 
                      padding: 10px; 
                      background-color: #f9f9f9; 
                      border: 1px solid #ccc; 
                      border-radius: 5px; 
                      text-align: left; 
                      cursor: pointer; 
                      font-size: 14px;
                      display: flex; 
                      align-items: center;
                      justify-content: space-between;
                    "
                    onclick="updateMarkerPosition(${item.id}, ${lat}, ${lng})"
                  >
                    <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 70%;">${fileName}</span>
                    <span style="font-weight: bold;">ID: ${item.id}</span>
                  </button>
                </li>
              `;
            })
            .join('')}
        </ul>
      </div>
    `;

  return `
    <div style="padding: 15px; font-size: 14px; background-color: white; 
                border-radius: 5px; border: 1px solid #ccc; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
                <button style="position: absolute; top: 5px; right: 5px; border: none; background: none; 
                         cursor: pointer; font-size: 16px;" 
                  onclick="closeInfoWindow()">X</button>
      <p style="margin: 0;">클릭한 좌표:</p>
      <p style="margin: 5px 0;">위도: ${lat}</p>
      <p style="margin: 5px 0;">경도: ${lng}</p>
      ${itemListContent}
    </div>
  `;
}

const NaverMapLayout = ({
  data,
  currentPosition,
  editLatLong,
  editStartingPoint,
  showViewer,
}) => {
  const navermaps = useNavermaps();
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const infoWindowRef = useRef(null);
  const [defaultPosition, setDefaultPosition] = useState({
    latitude: currentPosition.lat,
    longitude: currentPosition.lng,
  });
  const [panoPoints, setPanoPoints] = useState(data);
  const [updatedPoints, setUpdatedPoints] = useState(data);

  const [clickedMarkers, setClickedMarkers] = useState();
  let dragEndTimeout;

  const findClosestPoint = (latitude, longitude) => {
    let minDistance = Number.MAX_VALUE;
    let minIndex = 0;

    panoPoints.forEach((point, index) => {
      const distance = Math.sqrt(
        Math.pow(latitude - point.latitude, 2) +
          Math.pow(longitude - point.longitude, 2)
      );
      if (distance < minDistance) {
        minDistance = distance;
        minIndex = index;
      }
    });
  };
  useEffect(() => {
    infoWindowRef.current = new navermaps.InfoWindow({
      content: '',
      borderWidth: 1,
      maxWidth: 300,
      disableAnchor: true,
    });

    window.handleResetPosition = (pointId) => {
      closeInfoWindow();
      handleResetPosition(pointId);
    };

    window.updateMarkerPosition = (pointId, latitude, longitude) => {
      closeInfoWindow();
      updateMarkerPosition(pointId, latitude, longitude);
    };
    window.showViewer = (pointId) => {
      showViewer(pointId);
    };
    window.editStartingPoint = (pointId) => {
      closeInfoWindow();
      editStartingPoint(pointId);
    };
    window.closeInfoWindow = () => {
      closeInfoWindow();
    };

    return () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.setMap(null);
      }
      delete window.showViewer;
      delete window.handleResetPosition;
      delete window.updateMarkerPosition;
      delete window.editStartingPoint;
      delete window.closeInfoWindow;
    };
  }, []);

  // Map 정보 세팅
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setOptions({
        minZoom: 13,
        maxZoom: 20,
        boundaryOffset: 0,
      });
    }
  }, [mapRef]);

  // 현위치 트래킹
  useEffect(() => {
    if (currentPosition && mapRef.current) {
      mapRef.current.panTo(
        new navermaps.LatLng(
          currentPosition.latitude,
          currentPosition.longitude
        )
      );
      findClosestPoint(currentPosition.latitude, currentPosition.longitude);
    }
  }, [currentPosition]);

  useEffect(() => {
    setPanoPoints(data);
    setUpdatedPoints(data);
    const validData = data.filter(
      (item) => item.latitude !== null && item.longitude !== null
    );

    const total = validData.reduce(
      (acc, item) => {
        acc.latitude += parseFloat(item.latitude);
        acc.longitude += parseFloat(item.longitude);
        return acc;
      },
      { latitude: 0, longitude: 0 }
    );
    if (total.latitude !== 0 && total.longitude !== 0 && validData.length > 0) {
      setDefaultPosition({
        latitude: total.latitude / validData.length,
        longitude: total.longitude / validData.length,
      });
    }
    setLoading(false);
  }, [data]);

  const handleMapClick = (e) => {
    const itemsWithoutLatLong = data.filter(
      (item) => item.latitude === null || item.longitude === null
    );
    if (itemsWithoutLatLong.length > 0) {
      if (e.type) {
        const lat = e.coord.lat();
        const lng = e.coord.lng();

        const infoWindow = infoWindowRef.current;
        if (infoWindow) {
          infoWindow.setPosition(new navermaps.LatLng(lat + 0.00002, lng));
          infoWindow.setContent(
            generateInfoWindowContentFromMap(lat, lng, itemsWithoutLatLong)
          );
          infoWindow.open(mapRef.current);
          setClickedMarkers({ latitude: lat, longitude: lng });
        }
      } else {
        const lat = clickedMarkers.latitude;
        const lng = clickedMarkers.longitude;

        const infoWindow = infoWindowRef.current;
        if (infoWindow) {
          infoWindow.setPosition(new navermaps.LatLng(lat + 0.00002, lng));
          infoWindow.setContent(
            generateInfoWindowContentFromMap(lat, lng, itemsWithoutLatLong)
          );
          infoWindow.open(mapRef.current);
          setClickedMarkers({ latitude: lat, longitude: lng });
        }
      }
    }
  };

  const updateMarkerPosition = async (pointId, latitude, longitude) => {
    const updatedPoint = updatedPoints.find((point) => point.id === pointId);
    if (updatedPoint) {
      await editLatLong([
        {
          id: pointId,
          latitude: latitude,
          longitude: longitude,
        },
      ]);
    }
  };

  const handleResetPosition = (pointId) => {
    const originalPoint = panoPoints.find((point) => point.id === pointId);
    setUpdatedPoints((prevPoints) => {
      return prevPoints.map((point) =>
        point.id === pointId
          ? {
              ...point,
              latitude: originalPoint.latitude,
              longitude: originalPoint.longitude,
            }
          : point
      );
    });
  };
  const handleMarkerClick = (point) => {
    const infoWindow = infoWindowRef.current;
    if (infoWindow) {
      closeInfoWindow();
      const originalPoint = panoPoints.find((item) => item.id === point.id);
      const isSamePosition =
        originalPoint.latitude === point.latitude &&
        originalPoint.longitude === point.longitude;

      if (isSamePosition) {
        infoWindow.setPosition(
          new navermaps.LatLng(point.latitude, point.longitude)
        );
        infoWindow.setContent(generateInfoWindowContentFromMarker(point));
        infoWindow.open(mapRef.current);
      } else {
        infoWindow.setPosition(
          new navermaps.LatLng(point.latitude, point.longitude)
        );
        infoWindow.setContent(
          generateInfoWindowContentFromMarker(point, originalPoint)
        );
        infoWindow.open(mapRef.current);
      }
    }
  };

  const handleMarkerDragEnd = (e, pointId) => {
    const infoWindow = infoWindowRef.current;

    const newLat = e.coord.lat();
    const newLng = e.coord.lng();

    setUpdatedPoints((prevPoints) => {
      const updatedPoints = prevPoints.map((point) =>
        point.id === pointId
          ? { ...point, latitude: newLat, longitude: newLng }
          : point
      );

      const point = updatedPoints.find((point) => point.id === pointId);
      const originalPoint = panoPoints.find((point) => point.id === pointId);
      if (point && infoWindow) {
        infoWindow.setPosition(new navermaps.LatLng(newLat, newLng));
        infoWindow.setContent(
          generateInfoWindowContentFromMarker(point, originalPoint)
        );
        infoWindow.open(mapRef.current);
      }

      return updatedPoints;
    });

    if (dragEndTimeout) {
      clearTimeout(dragEndTimeout);
    }

    // 1초 후에 드래그 상태를 false로 설정
    dragEndTimeout = setTimeout(() => {
      setIsDragging(false);
    }, 500);
  };

  // 닫기 버튼의 핸들러 함수
  function closeInfoWindow() {
    const infoWindow = infoWindowRef.current;
    if (infoWindow) {
      infoWindow.close();
    }
  }
  return (
    <Container>
      <MapDiv
        style={{
          width: '100%',
          height: 'calc(100% - 20px)',
        }}
      >
        {!loading && (
          <NaverMap
            ref={mapRef}
            defaultCenter={
              new navermaps.LatLng(
                defaultPosition.latitude,
                defaultPosition.longitude
              )
            }
            defaultZoom={17}
            draggable={true}
            onClick={(e) => handleMapClick(e)}
            onCenterChanged={() => {
              if (!isDragging) {
                closeInfoWindow();
              }
            }}
          >
            {updatedPoints.map(
              (point) =>
                point.latitude &&
                point.longitude && (
                  <Marker
                    key={point.id}
                    position={
                      new navermaps.LatLng(point.latitude, point.longitude)
                    }
                    icon={{
                      content: `<div style="
                    width: 30px;
                    height: 30px;
                    background-image: url(${point.img_thumbnail});
                    background-size: cover;
                    background-position: center;
                    border-radius: 10px;
                    border: ${point.is_starting ? '4px solid #000000' : 'none'},
                  "></div>`,
                      anchor: new navermaps.Point(10, 10),
                    }}
                    draggable={true}
                    onDragstart={() => {
                      closeInfoWindow();
                      setIsDragging(true);
                    }}
                    onDragend={(e) => handleMarkerDragEnd(e, point.id)}
                    onClick={() => handleMarkerClick(point)}
                  />
                )
            )}
            {clickedMarkers && (
              <Marker
                key={'clicked'}
                position={
                  new navermaps.LatLng(
                    clickedMarkers.latitude,
                    clickedMarkers.longitude
                  )
                }
                icon={{
                  content: `<div style="
                    width: 20px;
                    height: 20px;
                    background-color: rgba(0, 128, 255, 0.7);
                    border-radius: 50%;
                    border: 2px solid #0078ff;
                  "></div>`,
                  anchor: new navermaps.Point(10, 10),
                }}
                onClick={(e) => handleMapClick(e)}
              />
            )}
          </NaverMap>
        )}
      </MapDiv>
    </Container>
  );
};

const Container = styled.div`
  width: ${(props) => (props.isViewerOpen ? '1000px' : '500px')};
  height: 500px;
  display: flex;
  flex-direction: row;
  transition: width 0.3s ease; /* 부드러운 전환 효과 */
`;

export default NaverMapLayout;
